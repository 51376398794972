<template>
  <section class="invoice-preview-wrapper">
    <b-row
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <b-img
                    :src="require('@/assets/images/logo/mutumed-mini.png')"
                    alt="logo"
                  />
                  <h3 class="text-primary invoice-logo">
                    BUKTI PELAKSANAAN KALIBRASI
                  </h3>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Nama: 
                  </p>
                  <p class="invoice-date">
                    {{ instansi.name }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Alamat:
                  </p>
                  <p class="invoice-date">
                    ............................
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Tanggal Kalibrasi:
                  </p>
                  <p class="invoice-date">
                    ............................
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Hal:
                  </p>
                  <p class="invoice-date">
                    ............................
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="products"
            :fields="['nama', 'merk', 'tipe', 'seri', 'ruangan', 'keterangan', 'qr']"
          >
            <template #cell(nama)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{data.item.name}}
              </b-card-text>
            </template>

            <template #cell(tipe)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{data.item.types}}
              </b-card-text>
            </template>

            <template #cell(seri)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{data.item.code}}
              </b-card-text>
            </template>

            <template #cell(keterangan)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{data.item.note}}
              </b-card-text>
            </template>
            
            <template #cell(qr)="data">
              <b-avatar
                  :src="`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=localhost:8080/warehouse/product/detail/${data.item.id_product}?choe=UTF-8`"
                  size="40px"
                  rounded
              />
            </template>
          </b-table-lite>

          <!-- Spacer -->
          <hr class="invoice-spacing">
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">...........................................................</span>
                </b-card-text>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">...........................................................</span>
                </b-card-text>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-4">
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">...........................................................</span>
                </b-card-text>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title"></p>
                </div>
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">...........................................................</span>
                </b-card-text>
              </div>
            </div>
          </b-card-body>
          <hr>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>

          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Download
          </b-button> -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BAvatar, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import moduleApi from '@/store/module'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      products: [],
      instansi: [],
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BAvatar,
    BImg
  },
  methods: {
    fetchDataListProducs() { 
      moduleApi.fetchDataListProducs(this.$route.params.id_task)
        .then(response => {
            this.products  = response.data.data
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },
  },
  created() {
    this.fetchDataListProducs()
  },
  setup() {
    const { t } = useI18nUtils()
    const printInvoice = () => {
      window.print()
    }

    return {
      // i18n 
      t,
      printInvoice,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
